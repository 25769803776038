import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutOrder from "components/AboutOrder/AboutOrder";
import FindOrderField from "components/FindOrderField/FindOrderField";
import Layout from "pages/Layout/Layout";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import "./App.css";
import ContractOrder from "components/ContractOrder/ContractOrder";
import FindContractField from "components/FindContractField/FindContractField";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<FindOrderField />} />
          <Route path="offerNumber/:id" element={<AboutOrder />} />
          <Route path="findContract" element={<FindContractField />} />
          <Route path="contractNumber/:id" element={<ContractOrder />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
