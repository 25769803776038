import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import spinner from "assets/icons/spinner.svg";
import document from "assets/icons/document.svg";
import FindOrderField from "components/FindOrderField/FindOrderField";

import "./AboutOrder.css";

interface IData {
  id: number;
  delivery_number: string;
  delivery_date: string;
  start_address: string;
  price: number;
  pdf_file: string;
  customer: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
}

const AboutOrder = () => {
  const [data, setData] = useState<IData>();
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [status, setStatus] = useState<"idle" | "loading" | "error">("loading");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://sieben-api.info/api/v1/offers/for-customer/${id}/`, {
      method: "GET",
      body: null,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
        setStatus("idle");
      })
      .catch(() => setStatus("error"));
    // eslint-disable-next-line
  }, [id]);

  const goBack = () => {
    if (data?.id) {
      
      const updateData = {
        delivery_status: "ARRANGED",
      };

      // Make the PATCH request to update the contract status
      fetch(`https://sieben-api.info/api/v1/offers/${data?.id}/`, {
        method: "PATCH",
        body: JSON.stringify(updateData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Failed to update contract status");
          }
          // Optionally, you can handle the success response here
          // For example, you might want to navigate back after updating the status
          alert("Your offer has been confirmed");
          navigate(-1);
        })
        .catch((error) => {
          console.error(error);
          // Optionally, you can handle the error here
        });
    }
  };

  const error =
    status === "error" ? (
      <>
        <h1 style={{ color: "red" }} className="status">
          Es ist ein Serverfehler aufgetreten. Bitte versuchen Sie es später
          erneut.
        </h1>
        <FindOrderField />
      </>
    ) : null;
  const loading =
    status === "loading" ? (
      <img className="status" src={spinner} alt="" />
    ) : null;
  const content =
    status === "idle" && data
      ? getContent({
          number: data?.delivery_number,
          price: data?.price,
          adress: data?.start_address,
          firstName: data?.customer?.first_name,
          lastName: data?.customer?.last_name,
          date: data?.delivery_date,
          pdfFile: `https://sieben-api.info${data.pdf_file}`,
          goBack: goBack,
          checkboxValue,
          setCheckboxValue,
        })
      : null;

  return (
    <div className="wrapper">
      {error}
      {loading}
      {content}
    </div>
  );
};

function getContent(data: {
  number: string;
  price: number;
  adress: string;
  firstName: string;
  lastName: string;
  date: string;
  pdfFile: string;
  goBack: () => void;
  checkboxValue: boolean;
  setCheckboxValue: (arg: boolean) => void;
}) {
  const {
    number,
    price,
    adress,
    firstName,
    lastName,
    date,
    pdfFile,
    goBack,
    checkboxValue,
    setCheckboxValue,
  } = data;
  return (
    <>
      <div className="header">
        <p className="title">Angebot annehmen</p>
        <p className="number">Nummer {number || "---"}</p>
      </div>
      <ul className="data-list">
        <li className="list-item">
          <p className="text">Preis:</p>
          <p className="item-value">{price ? price + " €" : "---"}</p>
        </li>
        <li className="list-item">
          <p className="text">Versicherungssumme:</p>
          <p className="item-value">620 € pro Kubikmeter</p>
        </li>
        <li className="list-item">
          <p className="text">Adresse:</p>
          <p className="item-value">{adress || "---"}</p>
        </li>
        <li className="list-item">
          <p className="text">Name des Kunden:</p>
          <p className="item-value">{firstName + " " + lastName}</p>
        </li>
      </ul>
      <div className="pdf-block">
        <div className="checkboxBlock">
          <input
            checked={checkboxValue}
            className="checkbox"
            onChange={(e) => setCheckboxValue(!checkboxValue)}
            type="checkbox"
          />
          <p className="blueText">
            Ich akzeptiere die{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://sieben-umzuege.de/haftung/"
              className="link"
            >
              Haftungsbedingungen,
            </a>{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://sieben-umzuege.de/agb/"
              className="link"
            >
              AGBs
            </a>{" "}
            und <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://sieben-umzuege.de/datenschutz/"
              className="link"
            >
              Datenschutzbestimmungen
            </a>{" "}
            der Sieben Umzüge GmbH
          </p>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          className="btn secondary-btn"
          href={pdfFile}
        >
          <img src={document} alt="opem PDF document" />
          PDF-Übersicht
        </a>
      </div>
      <div className="footer-block">
        <div className="btn-group">
          <p
            className={`btn primary-btn ${checkboxValue ? "" : "disabled"}`}
            onClick={goBack}
          >
            Angebot annehmen
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            className="btn secondary-btn"
            href="https://sieben-umzuege.de/"
          >
            Website besuchen
          </a>
        </div>
        <div className="contact-group">
          <div
            className="contact"
            onClick={() => {
              navigator.clipboard.writeText("info@sieben-umzuege.de");
            }}
          >
            info@sieben-umzuege.de
          </div>
          <div
            className="contact"
            onClick={() => {
              navigator.clipboard.writeText("07142 4696600");
            }}
          >
            Tel.: 07142 4696600
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutOrder;
