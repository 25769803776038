import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import spinner from "assets/icons/spinner.svg";
import document from "assets/icons/document.svg";
import FindContractField from "components/FindContractField/FindContractField";

import "./ContractOrder.css";
import { transformDateFormat } from "utils/transformDateFormat";

interface IWorkerData {
  amount: number;
  coming_fee: number;
  hourly_wage: number;
}

interface ICarData {
  daily_wage: number;
  amount: number;
}

interface IWorker {
  name: IWorkerData;
}

interface ICar {
  name: ICarData;
}

interface IData {
  id: number;
  firm: string;
  start_address: string;
  end_address: string;
  date: string;
  start_time: string;
  price: number;
  workers_info: IWorker;
  cars_info: ICar;
}

const ContractOrder = () => {
  const [data, setData] = useState<IData>();
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [status, setStatus] = useState<"idle" | "loading" | "error">("loading");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://sieben-api.info/api/v1/contracts/${id}/`, {
      method: "GET",
      body: null,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
        setStatus("idle");
      })
      .catch(() => setStatus("error"));
    // eslint-disable-next-line
  }, [id]);

  const goBack = () => {
    if (id) {
      const updateData = {
        status: "ARRANGED",
        paid_comment: commentValue,
      };

      // Make the PATCH request to update the contract status
      fetch(`https://sieben-api.info/api/v1/contracts/${id}/`, {
        method: "PATCH",
        body: JSON.stringify(updateData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Failed to update contract status");
          }
          // Optionally, you can handle the success response here
          // For example, you might want to navigate back after updating the status
          alert("Your contract has been confirmed");
          navigate(-1);
        })
        .catch((error) => {
          console.error(error);
          // Optionally, you can handle the error here
        });
    }
  };

  const error =
    status === "error" ? (
      <>
        <h1 style={{ color: "red" }} className="status">
          Es ist ein Serverfehler aufgetreten. Bitte versuchen Sie es später
          erneut.
        </h1>
        <FindContractField />
      </>
    ) : null;
  const loading =
    status === "loading" ? (
      <img className="status" src={spinner} alt="" />
    ) : null;
  const content =
    status === "idle" && data
      ? getContent({
          id: data.id,
          firm: data.firm,
          price: data?.price,
          startAddress: data?.start_address,
          endAddress: data.end_address,
          date: data?.date,
          startTime: data.start_time,
          workersInfo: data.workers_info,
          carsInfo: data.cars_info,
          goBack: goBack,
          checkboxValue,
          setCheckboxValue,
          commentValue,
          setCommentValue,
        })
      : null;

  return (
    <div className="wrapper">
      {error}
      {loading}
      {content}
    </div>
  );
};

function getContent(data: {
  id: number;
  firm: string;
  price: number;
  startAddress: string;
  endAddress: string;
  date: string;
  startTime: string;
  workersInfo: IWorker;
  carsInfo: ICar;
  goBack: () => void;
  checkboxValue: boolean;
  setCheckboxValue: (arg: boolean) => void;
  commentValue: string;
  setCommentValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  const {
    id,
    firm,
    startAddress,
    endAddress,
    date,
    startTime,
    workersInfo,
    carsInfo,
    goBack,
    checkboxValue,
    setCheckboxValue,
    commentValue,
    setCommentValue,
  } = data;
  const mainInfo = [
    {
      title: "Ansprechpartner",
      info: firm,
    },
    {
      title: "Datum",
      info: date,
    },
    {
      title: "Startzeit",
      info: startTime,
    },
    {
      title: "Startadresse",
      info: startAddress,
    },
    {
      title: "Endadresse",
      info: endAddress,
    },
  ];

  return (
    <>
      <div className="header">
        <p className="title">Angebot annehmen</p>
        <p className="number">Angebotsnummer {id || "---"}</p>
      </div>
      <div className="mainInfo">
        <p className="text">Wichtigste Informationen</p>
        <div className="row">
          {mainInfo.map((v, index) => (
            <div key={index} className="greyBlock">
              <span className="grey">{v.title}</span>
              <p className="blue">
                {v.title === "Startzeit" && v.info === null
                  ? "00:00"
                  : v.title === "Datum"
                  ? transformDateFormat(v.info)
                  : v.info}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="addInfo">
          <div className="lkw">
            <div className="header">LKW informationen</div>
            <div className="lkwInfo">
              <div className="firstAddInfo">
                <div className="titles">
                  <p>Namen</p>
                  <p>Tagessatz</p>
                  <p>Anzahl</p>
                </div>
                {Object.keys(carsInfo).map((key, index) => {
                  const carKey = key as keyof typeof carsInfo;

                  return (
                    <div className="row">
                      <div className="greyBlock">
                        <p className="blue">{carKey}</p>
                      </div>
                      <div className="greyBlock">
                        <p className="blue">{carsInfo[carKey].daily_wage}</p>
                      </div>
                      <div className="greyBlock">
                        <p className="blue">{carsInfo[carKey].amount}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="arbeiter">
            <div className="header">Arbeiterinformationen</div>
            <div className="arbeiterInfo">
              <div className="firstAddInfo">
                <div className="titles">
                  <p>Namen</p>
                  <p>Stundenlohn</p>
                  <p>Anfahrtspauschale</p>
                  <p>Anzahl</p>
                </div>
                {Object.keys(workersInfo).map((key, index) => {
                  // Use type assertion to inform TypeScript about the key
                  const workerKey = key as keyof typeof workersInfo;

                  // Access the value using workersInfo[workerKey]
                  return (
                    <div key={index} className="row">
                      <div className="greyBlock">
                        <p className="blue">{workerKey}</p>
                      </div>
                      <div className="greyBlock">
                        <p className="blue">
                          {workersInfo[workerKey].hourly_wage}
                        </p>
                      </div>
                      <div className="greyBlock">
                        <p className="blue">
                          {workersInfo[workerKey].coming_fee}
                        </p>
                      </div>
                      <div className="greyBlock">
                        <p className="blue">{workersInfo[workerKey].amount}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="comment">
        <label htmlFor="comment">Kommentar</label>
        <textarea
          id="comment"
          name="comment"
          placeholder="Text"
          cols={30}
          rows={10}
          value={commentValue}
          onChange={(e) => setCommentValue(e.target.value)}
        ></textarea>
      </div>
      <div className="pdf-block">
        <div className="checkboxBlock">
          <input
            checked={checkboxValue}
            className="checkbox"
            onChange={(e) => setCheckboxValue(!checkboxValue)}
            type="checkbox"
          />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://sieben-umzuege.de/datenschutz/"
            className="blueText"
          >
            Ich akzeptiere die Haftung, AGB und Datenschutz der Sieben Umzüge
            GmbH und bin mir dessen bewusst,
            <br /> dass die Mindestabnahme pro Arbeiter 5 Stunden beträgt.
          </a>
        </div>
      </div>
      <div className="footer-block">
        <div className="btn-group">
          <p
            className={`btn primary-btn ${checkboxValue ? "" : "disabled"}`}
            onClick={goBack}
          >
            Angebot annehmen
          </p>
        </div>
        <div className="contact-group">
          <div
            className="contact"
            onClick={() => {
              navigator.clipboard.writeText("info@sieben-umzuege.de");
            }}
          >
            info@sieben-umzuege.de
          </div>
          <div
            className="contact"
            onClick={() => {
              navigator.clipboard.writeText("07142 4696600");
            }}
          >
            Tel.: 07142 4696600
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractOrder;
