import { Outlet } from "react-router-dom";
import logo from "../../assets/images/Logo.svg";
import award1 from "../../assets/images/award1.png";
import award2 from "../../assets/images/award2.png";
import award3 from "../../assets/images/award3.png";
import "./Layout.css";

const Layout = () => {
  return (
    <>
      <header>
        <img src={logo} alt="Logo" />
        <div className="awards">
          <img src={award1} alt="award_one" width={132} height={69} />
          <img src={award2} alt="award_two" width={142} height={68} />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.provenexpert.com/sieben-umzuege-gmbh/"
          >
            <img src={award3} alt="award_three" width={56} height={67} />
          </a>
        </div>
      </header>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
