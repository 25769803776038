import "./FindOrderField.css"
import { useState } from "react"

import { useNavigate } from 'react-router-dom';

const FindOrderField = () => {
  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const onClick = () => {
    if (value) {
      navigate(`/offerNumber/${value}`);
    }
  }

  return (
    <div className="FieldWrapper">
      <p>Geben Sie Ihre Bestellnummer aus der E-Mail ein</p>
      <div className="Field">
        <input value={value} onChange={(e) => setValue(e.target.value)} placeholder="0000" type="text" />
        <div className="fieldBtn" onClick={onClick}>OK</div>
      </div>
    </div>
  );
}



export default FindOrderField